import React from "react";
import Layout from "../../components/Layout/Layout";
import PageIntro from "../../components/PageIntro/PageIntro";
import { graphql } from "gatsby";
import pathBuilder from "../../utils/pathBuilder";

class ExamTemplate extends React.Component {
  render() {
    const {
      title,
      node_locale,
      contentful_id,
      subtitle,
      __typename,
      seoDescription,
      paperformId,
      seoTitle,
      slug,
    } = this.props.data.exam;

    //constructing environment.
    const environment = {
      lang: node_locale,
      id: contentful_id,
      path: pathBuilder(node_locale, __typename, slug),
      altHrefLang: [
        {
          lang: node_locale,
          path: pathBuilder(node_locale, __typename, slug),
        },
        {
          lang: this.props.data.altExam.node_locale,
          path: pathBuilder(
            this.props.data.altExam.node_locale,
            this.props.data.altExam.__typename,
            this.props.data.altExam.slug
          ),
        },
      ],
      seo: {},
    };
    if (seoDescription) {
      environment.seo.description = seoDescription;
    }
    if (seoTitle) {
      environment.seo.title = seoTitle;
    } else {
      environment.seo.title = title;
    }

    return (
      <Layout environment={environment}>
        <PageIntro bg="testColorBg">
          <h1>{title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: subtitle.childMarkdownRemark.html,
            }}
          />
        </PageIntro>
        <div data-paperform-id={paperformId}></div>
      </Layout>
    );
  }
}

export default ExamTemplate;

export const examQuery = graphql`
  query examQuery($lang: String!, $id: String!, $altLang: String!) {
    exam: contentfulExam(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      title
      slug
      node_locale
      contentful_id
      subtitle {
        childMarkdownRemark {
          html
        }
      }
      paperformId
      __typename
    }
    altExam: contentfulExam(
      contentful_id: { eq: $id }
      node_locale: { eq: $altLang }
    ) {
      title
      slug
      node_locale
      __typename
    }
  }
`;
